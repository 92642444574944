import { useState } from "react";
import {Link} from 'react-scroll'

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="flex justify-center font-bold p-10">
            <p className="text-5xl text-baseblue">Denniscleans</p>
        </div>
      
    );
};

export default NavBar;