
import Home from './components/Home';
import NavBar from './components/NavBar';

function App() {
  return (
    <div className="h-screen bg-darkgray">
      <NavBar />
      <Home />
      </div>
  );
}

export default App;
